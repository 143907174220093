/* <!-- light yellow ffe27e -->
  <!-- yellow ffd131 -->
  <!-- dark yellow e4b100 -->

  <!-- light green 2bb673 -->
  <!-- green 1C915A -->
  <!-- dark green 105132 -->

  <!-- light blue 6ca4e0 -->
  <!-- blue 2d7dd2 -->
  <!-- dark blue 205893 -->

  <!-- light orange ffbfb4 -->
  <!-- orange FF7E67 -->
  <!-- slightly darker orange ff6843 -->
  <!-- dark orange ff5334 -->

  <!-- light red f18e9b -->
  <!-- red e84a5f -->
  <!-- dark red cc1a32 --> */


  /* slack colors
  yellow #ecb22e
  green #2eb67D
  red #e01e5a
  lightblue #36c5f0
  darkgreen #007A5A
  darkblue #1264A3
  darkaubergine #4a154b
  nullaubergine #611f69
  activeaubergine #7c3085
  black #000000 */





:root {
  --sidebar_width: 300px;

  /* --yellow: #ffd131; */
  --yellow: #ffeb3b;
  --orange: #FF7E67;
  --red: #e84a5f;
  --darkred: #cc1a32;
  --blue: #2d7dd2;
  --darkblue: #205893;
  --darkgreenblue: #347474;
  --green: #2bb673;
  --forestgreen: #1C915A;
  --darkgreen: #1C915A;
  --white: white;
  --offwhite: #F8F8F8;
  /* --offwhite: #fafafa; */
  --lightgray: #f5f5f5;
  --gray: #eeeeee;
  --mediumgray: #d6d6d6;
  --darkgray: #888888;

  --black: black;
  --boxshadow: rgba(0,0,0, .55);
  --outline: rgba(0,0,0, .25);



  --yellow: #ecb22e;
  --green: #2eb67D;
  --red: #e01e5a;
  --tertiary: #1C915A;
  --tertiarydark: #167448;
  --tertiarylight: #49A77B;
  --secondary: #36c5f0;
  /* --primary: #35495e;
  --primarydark: #2f4154;
  --primarylight: #495b6e; */

  --primary: #191919;
  /* --primary: #000000; */
  --primarydark: #323232;
  --primarylight: #4c4c4c;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: Georgia, serif; */
  font-size: 16px;
  background-color: white;
  background-color: var(--white);
}
h1 {
  /* headline font */
  font-size: 36px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
h2 {
  /* headline font */
  font-size: 26px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
h3 {
  /* headline font */
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
h4 {
  /* headline font */
  font-size: 16px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
p,div {
  /* text font */
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}
p.bigger_text,
div.bigger_text {
  /* text font */
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}
p.smaller_text,
div.smaller_text {
  /* text font */
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #888888;
  color: var(--darkgray);
}
.thin {
  font-weight: 100;
}
.bold {
  font-weight: 700;
}
.logo_font {
  font-family: 'Helvetica Neue';
}
input {
  padding: 9px 10px;
  font-size: 17px;
  border-radius: 4px;
  width: 100%;
  max-width: 350px;
  border: 1px solid rgba(0,0,0, .25);
  border: 1px solid var(--outline);
}
input:focus {
  outline:0;
}
textarea {
  padding: 9px 10px;
  font-size: 17px;
  border-radius: 4px;
  width: 100%;
  max-width: 350px;
  border: 1px solid rgba(0,0,0, .25);
  border: 1px solid var(--outline);
  resize: none;
}
button {
  /* text font */
  /* font-size: 12; */
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 20px;

  /* font-size: 17px; */
  font-weight: 700;
  border-radius: 4px;
  /* width: 100%; */
  /* max-width: 370px; */
  /* box-shadow: 0 1px 0 var(--boxshadow); */
  border: none;
  cursor: pointer;
}
button.cta {
  border: solid 1px #191919;
  border: solid 1px var(--primary);
  background-color: #191919;
  background-color: var(--primary);
  color: white;
  color: var(--white);
}
button.cta:hover {
  background-color: #323232;
  background-color: var(--primarydark);
}
button.cta_alt {
  border: solid 1px #1C915A;
  border: solid 1px var(--tertiary);
  background-color: #1C915A;
  background-color: var(--tertiary);
  color: white;
  color: var(--white);
}
button.cta_alt:hover {
  background-color: #167448;
  background-color: var(--tertiarydark);
}
button.outline {
  border: solid 1px #191919;
  border: solid 1px var(--primary);
  background: none; 
  color: #191919; 
  color: var(--primary);
}
button.outline:hover {
  border: solid 1px #323232;
  border: solid 1px var(--primarydark);
}
button.outline_red {
  border: solid 1px #e01e5a;
  border: solid 1px var(--red);
  background: none; 
  color: #e01e5a; 
  color: var(--red);
}
button.outline_red:hover {
  border: solid 1px #cc1a32;
  border: solid 1px var(--darkred);
}
button.wide {
  /* border: solid 1px var(--primary);
  background-color: var(--primary);
  color: var(--white); */
  width: 100%;
  max-width: 370px;
}
/* button.wide:hover {
  background-color: var(--primarydark);
} */
button.flat {
  background: none;
  /* color: var(--white); */
}
button.flat:hover {
  background-color: #f5f5f5;
  background-color: var(--lightgray);
}


button.green_button {
  background-color: #1C915A;
  background-color: var(--forestgreen);
  color: white;
  color: var(--white);
}
button.yellow_button {
  background-color: #ecb22e;
  background-color: var(--yellow);
  color: white;
  color: var(--white);
}
button.blue_button {
  background-color: #2d7dd2;
  background-color: var(--blue);
  color: white;
  color: var(--white);
}
button.red_button {
  background-color: #e01e5a;
  background-color: var(--red);
  color: white;
  color: var(--white);
}
button.bank_toggle {
  max-width: 150px;
  margin-right: 10px;
  border: solid 2px #ecb22e;
  border: solid 2px var(--yellow);
}
button.bank_toggle_active {
  background-color: #ecb22e;
  background-color: var(--yellow);
  /* color: var(--white); */
}
button:focus {outline:0;}
button:disabled {
  background-color: #888888;
  background-color: var(--darkgray);
  border-color: #888888;
  border-color: var(--darkgray);
}
button:disabled:hover {
  background-color: #888888;
  background-color: var(--darkgray);
  cursor: not-allowed;
}
table { 
  border-collapse: collapse;
  text-align: left;
}

/* thead {
  background-color: var(--gray);
  padding: 5px;
}
tr {
  padding: 5px 0px;
} */
.table_header_row {
  background-color: #eeeeee;
  background-color: var(--gray);
}
.table_header_item {
  padding: 5px 10px;
  background-color: #eeeeee;
  background-color: var(--gray);
}
.table_row {
  /* background-color: var(--gray); */
  border-bottom: solid 1px rgba(0,0,0, .25);
  border-bottom: solid 1px var(--outline);
}
.table_item {
  padding: 10px;
  /* background-color: var(--gray); */
}
/* ::placeholder {
  color: red;
} */

.body_container {
  display: flex;
  /* height: calc(100vh - 64px); */
}
.sidebar_container {
  flex-basis: 1;
  /* height: calc(100vh - 64px); */
  /* 120 */
}
.dashboard_container {
  flex-basis: auto;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: calc(100vh - 35px);
  /* height: 100vh; */
  overflow: scroll;
}

.flex_wrap_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}
.flex_space_between {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.flex_space_between_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.align_center {
  align-items: center;
}
.align_bottom {
  align-items: flex-end;
}
.align_top {
  align-items: flex-start;
}
.gray_background {
  height: 100vh;
  background-color: #eeeeee;
  background-color: var(--gray);
}

.card {
  background-color: white;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0,0,0, .55);
  box-shadow: 0 1px 0 var(--boxshadow);
  border: 1px solid #eeeeee;
  border: 1px solid var(--gray);
  padding: 32px;
}
.card.sign_in_card {
  width: 100%;
  max-width: 400px;
}
.card.skinny {
  /* background-color: red; */
  /* padding-left: 10px;
  padding-right: 10px; */
  padding: 24px 16px;
}
.logo {
  /* font-size: 40px; */
  font-size: 32px;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 15px;
  color: black;
  color: var(--black);
  font-family: 'Helvetica Neue';
}
.logo.logo_small {
  font-size: 32px;
}
.poll {
  /* color: #B2B2B2; */
  /* color: #999999; */
  /* color: white; */
  /* color: #7f7f7f; */
  /* color: var(--red); */
  /* border-bottom: solid 5px var(--white); */
  color: black;
  color: var(--black);
  border-bottom: solid 5px black;
  border-bottom: solid 5px var(--black);
}
.growth {
  /* color: var(--gray); */
  /* color: #B2B2B2; */
  /* color: #eaecee; */
  color: #d6dade;
}
.poll_light {
  color: #888888;
}
.poll_dark {
  color: #191919;
  color: var(--primary);
  /* color: var(--darkgreen); */
  /* border-bottom: solid 5px var(--primary); */
  border-bottom: solid 5px #2d7dd2;
  border-bottom: solid 5px var(--blue);
  /* border-bottom: solid 5px #888888; */
}
/* .feedback { */
  /* color: #B2B2B2; */
  /* color: #999999; */
  /* color: white; */
  /* color: #7f7f7f; */
  /* color: var(--forestgreen); */
  /* color: var(--gray); */
  /* color: #ddd; */
/* } */

.header_logo {
  font-size: 32px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-family: 'Helvetica Neue';
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* text-align: left; */
  /* background-color: var(--forestgreen); */
  background-color: white;
  background-color: var(--white);
  color: black;
  color: var(--black); 
  /* background-color: #282828; */
  /* color: var(--white); */
  border-bottom: solid 1px rgba(0,0,0, .25);
  border-bottom: solid 1px var(--outline);
  padding-right: 30px;
  padding-left: 30px;
  height: 64px;
}
.company_name_header {
  /* background-color: red; */
  font-size: 24px;
  font-weight: 700;
}
.margin_auto {
  margin: auto;
}
.margin_top_5 {
  margin-top: 5px;
}
.margin_top_10 {
  margin-top: 10px;
}


/* sign up */
.sign_up_container {
  display: flex;
  min-height: 100vh;
}
.sign_up_left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8F8F8;
  background-color: var(--offwhite);
}
.sign_up_right {
  /* background-color: var(--blue); */
  background-color: white;
  background-color: var(--white);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invite_list_item {
  font-size: 18px;
  font-weight: 700;
  /* background-color: var(--forestgreen); */
  border: solid 2px #1C915A;
  border: solid 2px var(--forestgreen); 
  /* border: solid 2px var(--blue);  */
  /* color: var(--white); */
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 5px;
}
.invite_list_item:hover {
  background-color: #1C915A;
  background-color: var(--forestgreen);
  color: white;
  color: var(--white);
}

.center_margin {
  margin: auto;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.link {
  color: #2d7dd2;
  color: var(--blue);;
  cursor: pointer;
}
.link_white {
  color: white;
  color: var(--white);;
  cursor: pointer;
}
.error {
  color: #e01e5a;
  color: var(--red);;
}
.pointer {
  cursor: pointer;
}
.grabber {
  cursor: -webkit-grab;
  cursor: grab;
}
.half_width {
  /* width: 48%; */
  width: calc(50% - 5px);
}
.full_width {
  width: 100%;
}
.capitalize {
  text-transform: capitalize;
}
.right {
  text-align: right;
}
.spacer_10 {
  width: 100%;
  height: 10px;
}
.spacer_5 {
  width: 100%;
  height: 5px;
}
.margin_left_10 {
  margin-left: 10px;
}
.margin_right_10 {
  margin-right: 10px;
}
.scroll {
  overflow: scroll;
}
.react-dropdown-select {
  border-radius: 4px !important;
}

.modal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0,0,0, .55);
  box-shadow: 0 1px 0 var(--boxshadow);
  border: 1px solid #eeeeee;
  border: 1px solid var(--gray);
  padding: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 400px;
}
.modal_content:focus {
  outline:0;
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
}