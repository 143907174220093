/* <!-- light yellow ffe27e -->
  <!-- yellow ffd131 -->
  <!-- dark yellow e4b100 -->

  <!-- light green 2bb673 -->
  <!-- green 1C915A -->
  <!-- dark green 105132 -->

  <!-- light blue 6ca4e0 -->
  <!-- blue 2d7dd2 -->
  <!-- dark blue 205893 -->

  <!-- light orange ffbfb4 -->
  <!-- orange FF7E67 -->
  <!-- slightly darker orange ff6843 -->
  <!-- dark orange ff5334 -->

  <!-- light red f18e9b -->
  <!-- red e84a5f -->
  <!-- dark red cc1a32 --> */


  /* slack colors
  yellow #ecb22e
  green #2eb67D
  red #e01e5a
  lightblue #36c5f0
  darkgreen #007A5A
  darkblue #1264A3
  darkaubergine #4a154b
  nullaubergine #611f69
  activeaubergine #7c3085
  black #000000 */





:root {
  --sidebar_width: 300px;

  /* --yellow: #ffd131; */
  --yellow: #ffeb3b;
  --orange: #FF7E67;
  --red: #e84a5f;
  --darkred: #cc1a32;
  --blue: #2d7dd2;
  --darkblue: #205893;
  --darkgreenblue: #347474;
  --green: #2bb673;
  --forestgreen: #1C915A;
  --darkgreen: #1C915A;
  --white: white;
  --offwhite: #F8F8F8;
  /* --offwhite: #fafafa; */
  --lightgray: #f5f5f5;
  --gray: #eeeeee;
  --mediumgray: #d6d6d6;
  --darkgray: #888888;

  --black: black;
  --boxshadow: rgba(0,0,0, .55);
  --outline: rgba(0,0,0, .25);



  --yellow: #ecb22e;
  --green: #2eb67D;
  --red: #e01e5a;
  --tertiary: #1C915A;
  --tertiarydark: #167448;
  --tertiarylight: #49A77B;
  --secondary: #36c5f0;
  /* --primary: #35495e;
  --primarydark: #2f4154;
  --primarylight: #495b6e; */

  --primary: #191919;
  /* --primary: #000000; */
  --primarydark: #323232;
  --primarylight: #4c4c4c;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: Georgia, serif; */
  font-size: 16px;
  background-color: white;
  background-color: var(--white);
}
h1 {
  /* headline font */
  font-size: 36px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
h2 {
  /* headline font */
  font-size: 26px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
h3 {
  /* headline font */
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
h4 {
  /* headline font */
  font-size: 16px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
p,div {
  /* text font */
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}
p.bigger_text,
div.bigger_text {
  /* text font */
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}
p.smaller_text,
div.smaller_text {
  /* text font */
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #888888;
  color: var(--darkgray);
}
.thin {
  font-weight: 100;
}
.bold {
  font-weight: 700;
}
.logo_font {
  font-family: 'Helvetica Neue';
}
input {
  padding: 9px 10px;
  font-size: 17px;
  border-radius: 4px;
  width: 100%;
  max-width: 350px;
  border: 1px solid rgba(0,0,0, .25);
  border: 1px solid var(--outline);
}
input:focus {
  outline:0;
}
textarea {
  padding: 9px 10px;
  font-size: 17px;
  border-radius: 4px;
  width: 100%;
  max-width: 350px;
  border: 1px solid rgba(0,0,0, .25);
  border: 1px solid var(--outline);
  resize: none;
}
button {
  /* text font */
  /* font-size: 12; */
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 20px;

  /* font-size: 17px; */
  font-weight: 700;
  border-radius: 4px;
  /* width: 100%; */
  /* max-width: 370px; */
  /* box-shadow: 0 1px 0 var(--boxshadow); */
  border: none;
  cursor: pointer;
}
button.cta {
  border: solid 1px #191919;
  border: solid 1px var(--primary);
  background-color: #191919;
  background-color: var(--primary);
  color: white;
  color: var(--white);
}
button.cta:hover {
  background-color: #323232;
  background-color: var(--primarydark);
}
button.cta_alt {
  border: solid 1px #1C915A;
  border: solid 1px var(--tertiary);
  background-color: #1C915A;
  background-color: var(--tertiary);
  color: white;
  color: var(--white);
}
button.cta_alt:hover {
  background-color: #167448;
  background-color: var(--tertiarydark);
}
button.outline {
  border: solid 1px #191919;
  border: solid 1px var(--primary);
  background: none; 
  color: #191919; 
  color: var(--primary);
}
button.outline:hover {
  border: solid 1px #323232;
  border: solid 1px var(--primarydark);
}
button.outline_red {
  border: solid 1px #e01e5a;
  border: solid 1px var(--red);
  background: none; 
  color: #e01e5a; 
  color: var(--red);
}
button.outline_red:hover {
  border: solid 1px #cc1a32;
  border: solid 1px var(--darkred);
}
button.wide {
  /* border: solid 1px var(--primary);
  background-color: var(--primary);
  color: var(--white); */
  width: 100%;
  max-width: 370px;
}
/* button.wide:hover {
  background-color: var(--primarydark);
} */
button.flat {
  background: none;
  /* color: var(--white); */
}
button.flat:hover {
  background-color: #f5f5f5;
  background-color: var(--lightgray);
}


button.green_button {
  background-color: #1C915A;
  background-color: var(--forestgreen);
  color: white;
  color: var(--white);
}
button.yellow_button {
  background-color: #ecb22e;
  background-color: var(--yellow);
  color: white;
  color: var(--white);
}
button.blue_button {
  background-color: #2d7dd2;
  background-color: var(--blue);
  color: white;
  color: var(--white);
}
button.red_button {
  background-color: #e01e5a;
  background-color: var(--red);
  color: white;
  color: var(--white);
}
button.bank_toggle {
  max-width: 150px;
  margin-right: 10px;
  border: solid 2px #ecb22e;
  border: solid 2px var(--yellow);
}
button.bank_toggle_active {
  background-color: #ecb22e;
  background-color: var(--yellow);
  /* color: var(--white); */
}
button:focus {outline:0;}
button:disabled {
  background-color: #888888;
  background-color: var(--darkgray);
  border-color: #888888;
  border-color: var(--darkgray);
}
button:disabled:hover {
  background-color: #888888;
  background-color: var(--darkgray);
  cursor: not-allowed;
}
table { 
  border-collapse: collapse;
  text-align: left;
}

/* thead {
  background-color: var(--gray);
  padding: 5px;
}
tr {
  padding: 5px 0px;
} */
.table_header_row {
  background-color: #eeeeee;
  background-color: var(--gray);
}
.table_header_item {
  padding: 5px 10px;
  background-color: #eeeeee;
  background-color: var(--gray);
}
.table_row {
  /* background-color: var(--gray); */
  border-bottom: solid 1px rgba(0,0,0, .25);
  border-bottom: solid 1px var(--outline);
}
.table_item {
  padding: 10px;
  /* background-color: var(--gray); */
}
/* ::placeholder {
  color: red;
} */

.body_container {
  display: flex;
  /* height: calc(100vh - 64px); */
}
.sidebar_container {
  flex-basis: 1;
  /* height: calc(100vh - 64px); */
  /* 120 */
}
.dashboard_container {
  flex-basis: auto;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: calc(100vh - 35px);
  /* height: 100vh; */
  overflow: scroll;
}

.flex_wrap_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}
.flex_space_between {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.flex_space_between_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.align_center {
  align-items: center;
}
.align_bottom {
  align-items: flex-end;
}
.align_top {
  align-items: flex-start;
}
.gray_background {
  height: 100vh;
  background-color: #eeeeee;
  background-color: var(--gray);
}

.card {
  background-color: white;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0,0,0, .55);
  box-shadow: 0 1px 0 var(--boxshadow);
  border: 1px solid #eeeeee;
  border: 1px solid var(--gray);
  padding: 32px;
}
.card.sign_in_card {
  width: 100%;
  max-width: 400px;
}
.card.skinny {
  /* background-color: red; */
  /* padding-left: 10px;
  padding-right: 10px; */
  padding: 24px 16px;
}
.logo {
  /* font-size: 40px; */
  font-size: 32px;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 15px;
  color: black;
  color: var(--black);
  font-family: 'Helvetica Neue';
}
.logo.logo_small {
  font-size: 32px;
}
.poll {
  /* color: #B2B2B2; */
  /* color: #999999; */
  /* color: white; */
  /* color: #7f7f7f; */
  /* color: var(--red); */
  /* border-bottom: solid 5px var(--white); */
  color: black;
  color: var(--black);
  border-bottom: solid 5px black;
  border-bottom: solid 5px var(--black);
}
.growth {
  /* color: var(--gray); */
  /* color: #B2B2B2; */
  /* color: #eaecee; */
  color: #d6dade;
}
.poll_light {
  color: #888888;
}
.poll_dark {
  color: #191919;
  color: var(--primary);
  /* color: var(--darkgreen); */
  /* border-bottom: solid 5px var(--primary); */
  border-bottom: solid 5px #2d7dd2;
  border-bottom: solid 5px var(--blue);
  /* border-bottom: solid 5px #888888; */
}
/* .feedback { */
  /* color: #B2B2B2; */
  /* color: #999999; */
  /* color: white; */
  /* color: #7f7f7f; */
  /* color: var(--forestgreen); */
  /* color: var(--gray); */
  /* color: #ddd; */
/* } */

.header_logo {
  font-size: 32px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-family: 'Helvetica Neue';
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* text-align: left; */
  /* background-color: var(--forestgreen); */
  background-color: white;
  background-color: var(--white);
  color: black;
  color: var(--black); 
  /* background-color: #282828; */
  /* color: var(--white); */
  border-bottom: solid 1px rgba(0,0,0, .25);
  border-bottom: solid 1px var(--outline);
  padding-right: 30px;
  padding-left: 30px;
  height: 64px;
}
.company_name_header {
  /* background-color: red; */
  font-size: 24px;
  font-weight: 700;
}
.margin_auto {
  margin: auto;
}
.margin_top_5 {
  margin-top: 5px;
}
.margin_top_10 {
  margin-top: 10px;
}


/* sign up */
.sign_up_container {
  display: flex;
  min-height: 100vh;
}
.sign_up_left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F8F8F8;
  background-color: var(--offwhite);
}
.sign_up_right {
  /* background-color: var(--blue); */
  background-color: white;
  background-color: var(--white);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invite_list_item {
  font-size: 18px;
  font-weight: 700;
  /* background-color: var(--forestgreen); */
  border: solid 2px #1C915A;
  border: solid 2px var(--forestgreen); 
  /* border: solid 2px var(--blue);  */
  /* color: var(--white); */
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 5px;
}
.invite_list_item:hover {
  background-color: #1C915A;
  background-color: var(--forestgreen);
  color: white;
  color: var(--white);
}

.center_margin {
  margin: auto;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.link {
  color: #2d7dd2;
  color: var(--blue);;
  cursor: pointer;
}
.link_white {
  color: white;
  color: var(--white);;
  cursor: pointer;
}
.error {
  color: #e01e5a;
  color: var(--red);;
}
.pointer {
  cursor: pointer;
}
.grabber {
  cursor: -webkit-grab;
  cursor: grab;
}
.half_width {
  /* width: 48%; */
  width: calc(50% - 5px);
}
.full_width {
  width: 100%;
}
.capitalize {
  text-transform: capitalize;
}
.right {
  text-align: right;
}
.spacer_10 {
  width: 100%;
  height: 10px;
}
.spacer_5 {
  width: 100%;
  height: 5px;
}
.margin_left_10 {
  margin-left: 10px;
}
.margin_right_10 {
  margin-right: 10px;
}
.scroll {
  overflow: scroll;
}
.react-dropdown-select {
  border-radius: 4px !important;
}

.modal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0,0,0, .55);
  box-shadow: 0 1px 0 var(--boxshadow);
  border: 1px solid #eeeeee;
  border: 1px solid var(--gray);
  padding: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 400px;
}
.modal_content:focus {
  outline:0;
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
}
.sidebar {
  /* height: calc(100vh - 64px); */
  height: calc(100vh - 35px);
  /* height: 100vh; */
  width: 280px;
  /* width: 300px !important; */

  /* background-color: var(--gray); */
  /* background-color: var(--yellow); */
  /* border-right: solid #ddd 1px; */

  /* background-color: var(--gray); */
  /* background-color: var(--mediumgray); */
  /* background-color: #eeeeee; */
  background-color:#eeeeee;
  
  /* background-color: var(--primary); */
  /* color: var(--white); */
  /* color: var(--lightgray); */
  /* background-color: var(--offwhite); */
  /* background-color: var(--white); */
  /* background-color: #f8fafd; */
  /* color: var(--white);
  background-color: #282828; */
  /* background-color: #33384E;
  background-color: var(--darkblue); */
  /* background-color: var(--black);
  background-color: #191919; */
  /* color: var(--white); */
  /* background-color: var(--green); */
  box-shadow: 0 1px 1px var(--boxshadow);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.sidebar_padding {
  padding-left: 10px;
  padding-right: 10px;
}
.stretch_connectors_container {
  flex: 1 1 auto;
}
.logo_container {
  height: 95px;
}
.view_item {
  padding: 8px;
  padding-left: 30px;
  /* border-top: solid var(--offwhite) 1px;
  border-bottom: solid var(--offwhite) 1px; */
  cursor: pointer;
}
.view_item:hover {
  /* background-color: var(--primarydark); */
  background-color: var(--mediumgray);

  /* color: var(--lightgray); */
  /* width: 240px; */
}
.view_active {
  /* padding-left: 25px; */
  /* background-color: var(--primarylight); */
  /* width: calc(100% + 1px); */
  /* height: 100px; */
  /* width: 241px; */
  /* border: solid 1px var(--outline); */
  /* border-right: none;
  border-left: solid 5px var(--forestgreen); */

  /* background-color: var(--white); */
  /* background-color: var(--offwhite); */
  /* background-color: #fafafa; */
  background-color: white;
  
  color: var(--black);
}
/* .view_active:hover {
  
  background-color: var(--white);
  color: var(--black);
} */
.team_list {
  /* background-color: var(--offwhite); */
  /* background-color:#eeeeee; */
  /* background-color: #fafafa; */
  background-color: white;
  color: var(--black);
  /* padding-left: 35px; */
  /* width: 241px; */
  /* background-color: var(--red); */
}

.team_list_item {
  cursor: pointer;
  /* padding: 8px 16px; */
  padding: 8px 45px;
  height: 18px;
  display: flex;
  direction: row;
  align-content: center;
  /* width: 241px; */
  /* padding-left: 50px; */
  /* padding-left: 40px; */
  /* width: fit-content; */
}
.team_list_item:hover {
  background-color: #eee;
}
.team_list_item_active {
  background-color: var(--blue);
  /* background-color: var(--forestgreen); */
  color: var(--white);
  /* background-color: var(--white); */
  /* background-color: #ffffff; */
  /* color: var(--black); */
  /* border-radius: 20px; */
  /* border-bottom: solid 4px var(--forestgreen); */
}
.team_list_item_active:hover {
  background-color: var(--blue);
}
.section_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.section_header_name {
  font-size: 18px;
  font-weight: 700;
}
.sidebar_filter {
  width: 100%;
  max-width: calc(var(--sidebar_width) - 60px);
  /* background-color: var(--primarylight);
  color: var(--lightgray); */

  /* background-color: var(--mediumgray); */
  background-color: var(--white);
  border: none;
  /* color: var(--lightgray); */

  
}
.sidebar_filter::-webkit-input-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter::-moz-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter:-ms-input-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter::-ms-input-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter::placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
/* dashboard */
.tab_row {
  display: flex;
  border-bottom: solid 1px var(--outline);
  /* border-bottom: solid 1px var(--gray); */
}
.tab {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}
.active_tab {
  /* color: var(--blue);
  border-bottom: solid 5px var(--blue); */
  /* color: var(--forestgreen); */
  border-bottom: solid 5px var(--forestgreen);
}

/* users */
.user_row {
  padding: 15px;
  border-top: solid 1px lightgray;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.question_row {
  padding: 15px;
  border-bottom: solid 1px lightgray;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.good_average {
  background-color: var(--forestgreen);
  color: var(--white);
}
.bad_average {
  background-color: var(--red);
  color: var(--white);
}
.neutral_average {
  background-color: var(--yellow);
  color: var(--black);
}
.averages_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}
.single_schedule_card_container {
  /* border: solid var(--gray) 2px; */
  border-radius: 4px;


  /* background-color: var(--white); */
  /* border-radius: 4px; */
  box-shadow: 0 1px 0 var(--boxshadow);
  border: 1px solid var(--gray);
  /* padding: 32px; */
}
.single_schedule_card_box {
  /* padding: 8px; */
  height: 175px;
  /* background-color: var(--offwhite); */
  background-color: var(--lightgray);
  /* background-color: var(--black); */
  border: solid var(--white) 2px;
  /* border: solid var(--boxshadow) 1px; */
}
.single_schedule_card {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  /* padding-top: 10px;
  padding-bottom: 10px; */
  text-align: left;
  padding: 15px;
  /* padding-bottom: 0px; */
  border: 1px solid var(--gray);
  border-radius: 4px;
  box-shadow: 0 1px 0 var(--boxshadow);
}
.single_schedule_card_black {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding: 15px;
  border: 1px solid var(--gray);
  border-radius: 4px;
  box-shadow: 0 1px 0 var(--boxshadow);
  background-color: var(--white);

  text-align: center;
  flex: display 1;
  justify-content: center;
  /* align-items: center; */
  cursor: -webkit-grab;
  cursor: grab;
}
.single_average_card_container {
  padding: 5px;
  /* width: calc(33% - 10px); */
  width: calc(16.6% - 10px);
}
.schedule_box_date {
  /* margin-bottom: 5px; */
  padding: 5px;
  font-weight: 500;
  background-color: white;
  text-align: left;
}
.schedule_box_body {
  padding: 5px;
}
.single_schedule_row {
  padding: 10px;
  border-top: solid 1px var(--outline);
  display: flex;
  align-items: center;
  /* border-top: solid 1px var(--gray); */
}
.single_average_card {
  /* height: 100%; */
  /* padding-top: 10px;
  padding-bottom: 10px; */
  text-align: left;
  padding: 15px;
  padding-bottom: 0px;
  border: 1px solid var(--gray);
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 1px 0 var(--boxshadow);
  /* height: 100px; */
}
.single_average_card_title {
  font-size: 24px;
  font-weight: 100;
  /* font-style: italic; */
}
.single_average_card_score {
  font-size: 24px;
  font-weight: 700;
  text-align: right;
  padding-bottom: 5px;
}
.single_average_card_line {
  width: 100%;
  height: 5px;
  /* background-color: var(--black);  */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.average {
  padding: 10px;
  width: 50px;
  text-align: center;
}

.average_row {
  /* padding: 15px; */
  /* padding: 10px; */
  border-top: solid 1px lightgray;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: stretch;

}
.average_text {
  padding: 10px;
  width: calc(100% - 60px)
}
.average_number {
  align-items: stretch;
  width: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* free response */
.free_response_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* flex-flow: row wrap; */
  align-items: stretch;
  /* align-content: flex-start; */
}
.free_response_card_container {
  width: calc(50% - 5px);
}
.free_response_card {
  /* margin: 10px; */
  padding: 5px;
  /* align-items: stretch; */

  /* border: solid black 1px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.free_response_text {
  font-style: italic;
}
.free_response_details {
  text-align: right;
}
.tag_options {
  display: flex;
  flex-direction: row;
}
.tag {
  padding: 6px 10px;
  border-radius: 20px;
  margin: 2px;
  cursor: pointer;
}

/* questions */
.custom_question_input {
  max-width: 600px;
  width: -webkit-fill-available;
}
.custom_question_option_select {
  /* max-width: 372px; */
  max-width: 620px;
  /* max-width: 800px; */
}
.custom_question_option_placeholder {
  /* background-color: red; */
  padding: 2px;
}
.custom_question_option_arrow {
  /* background-color: red; */
  /* padding: 2px; */
  margin-top: 3px;
}

/* .myOptionClassName {
  background-color: red;
  color: blue;
}
.myOptionClassName:hover {
  background-color: green;
  color: red;
} */
/* .myMenuClassName {
  color: red;
  background-color: blue;
} */
.Dropdown-option {
  background-color: var(--white);
}
.Dropdown-option.is-selected {
  background-color: var(--gray) !important;
}
.answer_preview {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* width: 100%; */
  align-items: stretch;
  /* width: 100%; */
}
.answer_preview_container {
  /* background-color: red; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.sample_answer_container {
  padding: 5px;
  /* width: 100px; */
  text-align: center;
  /* flex-basis: 1; */
}
.sample_answer {
  padding: 10px;
  background-color: var(--gray);
  /* height: 35px; */
}
.pos_neg_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.answer_direction_option {
  font-size: 18px;
  font-weight: 700;
  /* padding: 10px; */
}
.answer_direction_active {
  background-color: var(--blue);
  color: var(--white);
}
.radio_option {
  width: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.radio_group {
  text-align: left;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

/* banner */
.banner {
  width: 100%;
  height: 35px;
  background-color: var(--blue);
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown_tag {
  background-color: var(--blue);
  color: var(--white);
  padding: 5px 8px 5px;
  border-radius: 15px;
}
.dropdown_container {
  text-align: left;
}
.dropdown_header {
  border-top: solid 1px var(--gray);
  padding: 5px 8px;
  font-weight: 700;
  text-transform: capitalize;
}
.dropdown_item {
  padding: 8px 12px;
}
.dropdown_item_active {
  background-color: var(--blue);
  color: var(--white);
}
/* .row_selected {
  background-color: red;
} */
.answer_detail {
  padding: 10px;
}

.schedule_box_container {
  background-color: blue;
  /* width: 20%; */
  padding: 5px;
  flex: 1 0 20%;
}
.schedule_box_inside {
  background-color: pink;
  padding: 5px;
}
.schedule_box_title {
  background-color: yellow;
  width: 20%;
  text-align: center;
}

.test_behind {
  background-color: green;
  height: 200px;
  width: 200px;
  position: relative;
}
.test_behind_inside {
  background-color: pink;
  position: absolute;
  top: 0px;
  right: 0px;
}
.test_container {
  background-color: aliceblue;
  width: 100%;
}

/* salesforce colors
https://www.salesforce.com/blog/2018/05/new-research-color-performance.html */


/* vintage apple */
  /* --lightblue: #009DDC;
  --purple: #963E97;
  --red: #E03A3E;
  --orange: #F68220;
  --yellow: #FCB827;
  --green: #63BB47; */

  /* technicolor */
  /* --green: #508736;
  --blue: #485087;
  --lightblue: #3989C7;
  --red: #D43E31;
  --orange: #E86430;
  --yellow: #F09420; */

  /* google material colors 500 */
  /* --green: #4caf50;
  --blue: #3f51b5;
  --lightblue: #2196f3;
  --red: #f44336;
  --orange: #ff9800;
  --yellow: #ffeb3b; */

  /* google material colors 700A */
  /* --green: #00c853;
  --blue: #304ffe;
  --red: #d50000;
  --orange: #ff6d00;
  --yellow: #ffd600; */

  /* wework colors */
  /* --green: #2C8D5D;
  --blue: #096D9F;
  --red: #E6494A;
  --orange: #DD8F24;
  --yellow: #E1CF15; */

  /* impossible foods */
  /* --green: #05DCA4;
  --blue: #3141EB;
  --red: #FF3F8D;
  --orange: #FB7504;
  --yellow: #FECF00; */

  /* custom material */
  /* --yellow: #ffeb3b;  500
  --green: #00c853;  A700
  --blue: #2979ff;  A400
  --red: #ff1744;  A400 
  --orange: #ff9100; A400 */

  /* impossible foods */
  /* --green: #009e73;
  --lightgreen: #94e3b1; */
  /* --blue: #0070d2; */
  /* --blue: #0068E2;
  --lightblue: #93c9f8;
  --aqua: #76ded9;
  --lightred: #d8706d;
  --red: #c23934;
  --orange: #ffb75d;
  --yellow: #F3CB54; 
  --lightpurple: #e2b1fa;
  --purple: #af5cd8; */



.green {
  background-color: var(--green);
}
.lightgreen {
  background-color: var(--lightgreen);
}
.forestgreen {
  background-color: var(--forestgreen);
}
.blue {
  background-color: var(--blue);
}
.lightblue {
  background-color: var(--lightblue);
}
.aqua {
  background-color: var(--aqua);
}
.lightred {
  background-color: var(--lightred);
}
.red {
  background-color: var(--red);
}
.orange {
  background-color: var(--orange);
}
.yellow {
  background-color: var(--yellow);
}
.lightpurple {
  background-color: var(--lightpurple);
}
.purple {
  background-color: var(--purple);
}
.black {
  background-color: var(--black);
}
.white {
  background-color: var(--white);
}
.darkgreen {
  background-color: var(--darkgreen);
}
.secondary {
  background-color: var(--secondary);
}
.primary {
  background-color: var(--primary);
}
.primarydark {
  background-color: var(--primarydark);
}
.primarylight {
  background-color: var(--primarylight);
}


.blue_font {
  color: var(--blue);
}
.green_font {
  color: var(--orange);
}
.yellow_font {
  color: var(--green);
}
.red_font {
  color: var(--red);
}

.test_1 {
  width: 100%;
  height: 40px;
  background-color: var(--yellow);
}
.test_2 {
  width: 100%;
  height: 40px;
  background-color: var(--orange);
}
.test_3 {
  width: 100%;
  height: 40px;
  background-color: var(--red);
}
.test_4 {
  width: 100%;
  height: 40px;
  background-color: var(--blue);
  /* background-color: var(--lightblue); */
}
.test_5 {
  width: 100%;
  height: 40px;
  background-color: var(--darkgreenblue);
}
.test_6 {
  width: 100%;
  height: 40px;
  background-color: var(--green);
}
.test_7 {
  width: 100%;
  height: 40px;
  background-color: var(--forestgreen);
}
.test_8 {
  width: 100%;
  height: 40px;
  background-color: var(--white);
}
.test_9 {
  width: 100%;
  height: 40px;
  background-color: var(--black);
}

.color_row {
  /* height: 50px; */
  width: 100%;
  border-collapse: collapse;
}
.one,
.two,
.three,
.four,
.five {
  height: 30px;
}


/* body {
  margin: 0; */
  /* font-family: 'Helvetica Neue'; */
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 16px; */
  /* background-color: blue; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background-color: var(--lightgray); */
  /* background-color: var(--white);
} */

.test_color_box {
  width: 100px;
  height: 30px;
  margin: 5px
}
