/* salesforce colors
https://www.salesforce.com/blog/2018/05/new-research-color-performance.html */


/* vintage apple */
  /* --lightblue: #009DDC;
  --purple: #963E97;
  --red: #E03A3E;
  --orange: #F68220;
  --yellow: #FCB827;
  --green: #63BB47; */

  /* technicolor */
  /* --green: #508736;
  --blue: #485087;
  --lightblue: #3989C7;
  --red: #D43E31;
  --orange: #E86430;
  --yellow: #F09420; */

  /* google material colors 500 */
  /* --green: #4caf50;
  --blue: #3f51b5;
  --lightblue: #2196f3;
  --red: #f44336;
  --orange: #ff9800;
  --yellow: #ffeb3b; */

  /* google material colors 700A */
  /* --green: #00c853;
  --blue: #304ffe;
  --red: #d50000;
  --orange: #ff6d00;
  --yellow: #ffd600; */

  /* wework colors */
  /* --green: #2C8D5D;
  --blue: #096D9F;
  --red: #E6494A;
  --orange: #DD8F24;
  --yellow: #E1CF15; */

  /* impossible foods */
  /* --green: #05DCA4;
  --blue: #3141EB;
  --red: #FF3F8D;
  --orange: #FB7504;
  --yellow: #FECF00; */

  /* custom material */
  /* --yellow: #ffeb3b;  500
  --green: #00c853;  A700
  --blue: #2979ff;  A400
  --red: #ff1744;  A400 
  --orange: #ff9100; A400 */

  /* impossible foods */
  /* --green: #009e73;
  --lightgreen: #94e3b1; */
  /* --blue: #0070d2; */
  /* --blue: #0068E2;
  --lightblue: #93c9f8;
  --aqua: #76ded9;
  --lightred: #d8706d;
  --red: #c23934;
  --orange: #ffb75d;
  --yellow: #F3CB54; 
  --lightpurple: #e2b1fa;
  --purple: #af5cd8; */



.green {
  background-color: var(--green);
}
.lightgreen {
  background-color: var(--lightgreen);
}
.forestgreen {
  background-color: var(--forestgreen);
}
.blue {
  background-color: var(--blue);
}
.lightblue {
  background-color: var(--lightblue);
}
.aqua {
  background-color: var(--aqua);
}
.lightred {
  background-color: var(--lightred);
}
.red {
  background-color: var(--red);
}
.orange {
  background-color: var(--orange);
}
.yellow {
  background-color: var(--yellow);
}
.lightpurple {
  background-color: var(--lightpurple);
}
.purple {
  background-color: var(--purple);
}
.black {
  background-color: var(--black);
}
.white {
  background-color: var(--white);
}
.darkgreen {
  background-color: var(--darkgreen);
}
.secondary {
  background-color: var(--secondary);
}
.primary {
  background-color: var(--primary);
}
.primarydark {
  background-color: var(--primarydark);
}
.primarylight {
  background-color: var(--primarylight);
}


.blue_font {
  color: var(--blue);
}
.green_font {
  color: var(--orange);
}
.yellow_font {
  color: var(--green);
}
.red_font {
  color: var(--red);
}

.test_1 {
  width: 100%;
  height: 40px;
  background-color: var(--yellow);
}
.test_2 {
  width: 100%;
  height: 40px;
  background-color: var(--orange);
}
.test_3 {
  width: 100%;
  height: 40px;
  background-color: var(--red);
}
.test_4 {
  width: 100%;
  height: 40px;
  background-color: var(--blue);
  /* background-color: var(--lightblue); */
}
.test_5 {
  width: 100%;
  height: 40px;
  background-color: var(--darkgreenblue);
}
.test_6 {
  width: 100%;
  height: 40px;
  background-color: var(--green);
}
.test_7 {
  width: 100%;
  height: 40px;
  background-color: var(--forestgreen);
}
.test_8 {
  width: 100%;
  height: 40px;
  background-color: var(--white);
}
.test_9 {
  width: 100%;
  height: 40px;
  background-color: var(--black);
}

.color_row {
  /* height: 50px; */
  width: 100%;
  border-collapse: collapse;
}
.one,
.two,
.three,
.four,
.five {
  height: 30px;
}


/* body {
  margin: 0; */
  /* font-family: 'Helvetica Neue'; */
  /* font-family: Arial, Helvetica, sans-serif;
  font-size: 16px; */
  /* background-color: blue; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background-color: var(--lightgray); */
  /* background-color: var(--white);
} */

.test_color_box {
  width: 100px;
  height: 30px;
  margin: 5px
}