/* dashboard */
.tab_row {
  display: flex;
  border-bottom: solid 1px var(--outline);
  /* border-bottom: solid 1px var(--gray); */
}
.tab {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}
.active_tab {
  /* color: var(--blue);
  border-bottom: solid 5px var(--blue); */
  /* color: var(--forestgreen); */
  border-bottom: solid 5px var(--forestgreen);
}

/* users */
.user_row {
  padding: 15px;
  border-top: solid 1px lightgray;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.question_row {
  padding: 15px;
  border-bottom: solid 1px lightgray;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.good_average {
  background-color: var(--forestgreen);
  color: var(--white);
}
.bad_average {
  background-color: var(--red);
  color: var(--white);
}
.neutral_average {
  background-color: var(--yellow);
  color: var(--black);
}
.averages_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}
.single_schedule_card_container {
  /* border: solid var(--gray) 2px; */
  border-radius: 4px;


  /* background-color: var(--white); */
  /* border-radius: 4px; */
  box-shadow: 0 1px 0 var(--boxshadow);
  border: 1px solid var(--gray);
  /* padding: 32px; */
}
.single_schedule_card_box {
  /* padding: 8px; */
  height: 175px;
  /* background-color: var(--offwhite); */
  background-color: var(--lightgray);
  /* background-color: var(--black); */
  border: solid var(--white) 2px;
  /* border: solid var(--boxshadow) 1px; */
}
.single_schedule_card {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  /* padding-top: 10px;
  padding-bottom: 10px; */
  text-align: left;
  padding: 15px;
  /* padding-bottom: 0px; */
  border: 1px solid var(--gray);
  border-radius: 4px;
  box-shadow: 0 1px 0 var(--boxshadow);
}
.single_schedule_card_black {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  padding: 15px;
  border: 1px solid var(--gray);
  border-radius: 4px;
  box-shadow: 0 1px 0 var(--boxshadow);
  background-color: var(--white);

  text-align: center;
  flex: display;
  justify-content: center;
  /* align-items: center; */
  cursor: grab;
}
.single_average_card_container {
  padding: 5px;
  /* width: calc(33% - 10px); */
  width: calc(16.6% - 10px);
}
.schedule_box_date {
  /* margin-bottom: 5px; */
  padding: 5px;
  font-weight: 500;
  background-color: white;
  text-align: left;
}
.schedule_box_body {
  padding: 5px;
}
.single_schedule_row {
  padding: 10px;
  border-top: solid 1px var(--outline);
  display: flex;
  align-items: center;
  /* border-top: solid 1px var(--gray); */
}
.single_average_card {
  /* height: 100%; */
  /* padding-top: 10px;
  padding-bottom: 10px; */
  text-align: left;
  padding: 15px;
  padding-bottom: 0px;
  border: 1px solid var(--gray);
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 1px 0 var(--boxshadow);
  /* height: 100px; */
}
.single_average_card_title {
  font-size: 24px;
  font-weight: 100;
  /* font-style: italic; */
}
.single_average_card_score {
  font-size: 24px;
  font-weight: 700;
  text-align: right;
  padding-bottom: 5px;
}
.single_average_card_line {
  width: 100%;
  height: 5px;
  /* background-color: var(--black);  */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.average {
  padding: 10px;
  width: 50px;
  text-align: center;
}

.average_row {
  /* padding: 15px; */
  /* padding: 10px; */
  border-top: solid 1px lightgray;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: stretch;

}
.average_text {
  padding: 10px;
  width: calc(100% - 60px)
}
.average_number {
  align-items: stretch;
  width: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* free response */
.free_response_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* flex-flow: row wrap; */
  align-items: stretch;
  /* align-content: flex-start; */
}
.free_response_card_container {
  width: calc(50% - 5px);
}
.free_response_card {
  /* margin: 10px; */
  padding: 5px;
  /* align-items: stretch; */

  /* border: solid black 1px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.free_response_text {
  font-style: italic;
}
.free_response_details {
  text-align: right;
}
.tag_options {
  display: flex;
  flex-direction: row;
}
.tag {
  padding: 6px 10px;
  border-radius: 20px;
  margin: 2px;
  cursor: pointer;
}

/* questions */
.custom_question_input {
  max-width: 600px;
  width: -webkit-fill-available;
}
.custom_question_option_select {
  /* max-width: 372px; */
  max-width: 620px;
  /* max-width: 800px; */
}
.custom_question_option_placeholder {
  /* background-color: red; */
  padding: 2px;
}
.custom_question_option_arrow {
  /* background-color: red; */
  /* padding: 2px; */
  margin-top: 3px;
}

/* .myOptionClassName {
  background-color: red;
  color: blue;
}
.myOptionClassName:hover {
  background-color: green;
  color: red;
} */
/* .myMenuClassName {
  color: red;
  background-color: blue;
} */
.Dropdown-option {
  background-color: var(--white);
}
.Dropdown-option.is-selected {
  background-color: var(--gray) !important;
}
.answer_preview {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* width: 100%; */
  align-items: stretch;
  /* width: 100%; */
}
.answer_preview_container {
  /* background-color: red; */
  width: fit-content;
}
.sample_answer_container {
  padding: 5px;
  /* width: 100px; */
  text-align: center;
  /* flex-basis: 1; */
}
.sample_answer {
  padding: 10px;
  background-color: var(--gray);
  /* height: 35px; */
}
.pos_neg_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.answer_direction_option {
  font-size: 18px;
  font-weight: 700;
  /* padding: 10px; */
}
.answer_direction_active {
  background-color: var(--blue);
  color: var(--white);
}
.radio_option {
  width: auto;
  width: max-content;
}
.radio_group {
  text-align: left;
  margin: auto;
  width: max-content;
}

/* banner */
.banner {
  width: 100%;
  height: 35px;
  background-color: var(--blue);
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown_tag {
  background-color: var(--blue);
  color: var(--white);
  padding: 5px 8px 5px;
  border-radius: 15px;
}
.dropdown_container {
  text-align: left;
}
.dropdown_header {
  border-top: solid 1px var(--gray);
  padding: 5px 8px;
  font-weight: 700;
  text-transform: capitalize;
}
.dropdown_item {
  padding: 8px 12px;
}
.dropdown_item_active {
  background-color: var(--blue);
  color: var(--white);
}
/* .row_selected {
  background-color: red;
} */
.answer_detail {
  padding: 10px;
}

.schedule_box_container {
  background-color: blue;
  /* width: 20%; */
  padding: 5px;
  flex: 1 0 20%;
}
.schedule_box_inside {
  background-color: pink;
  padding: 5px;
}
.schedule_box_title {
  background-color: yellow;
  width: 20%;
  text-align: center;
}

.test_behind {
  background-color: green;
  height: 200px;
  width: 200px;
  position: relative;
}
.test_behind_inside {
  background-color: pink;
  position: absolute;
  top: 0px;
  right: 0px;
}
.test_container {
  background-color: aliceblue;
  width: 100%;
}
