.sidebar {
  /* height: calc(100vh - 64px); */
  height: calc(100vh - 35px);
  /* height: 100vh; */
  width: 280px;
  /* width: 300px !important; */

  /* background-color: var(--gray); */
  /* background-color: var(--yellow); */
  /* border-right: solid #ddd 1px; */

  /* background-color: var(--gray); */
  /* background-color: var(--mediumgray); */
  /* background-color: #eeeeee; */
  background-color:#eeeeee;
  
  /* background-color: var(--primary); */
  /* color: var(--white); */
  /* color: var(--lightgray); */
  /* background-color: var(--offwhite); */
  /* background-color: var(--white); */
  /* background-color: #f8fafd; */
  /* color: var(--white);
  background-color: #282828; */
  /* background-color: #33384E;
  background-color: var(--darkblue); */
  /* background-color: var(--black);
  background-color: #191919; */
  /* color: var(--white); */
  /* background-color: var(--green); */
  box-shadow: 0 1px 1px var(--boxshadow);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.sidebar_padding {
  padding-left: 10px;
  padding-right: 10px;
}
.stretch_connectors_container {
  flex: 1 1 auto;
}
.logo_container {
  height: 95px;
}
.view_item {
  padding: 8px;
  padding-left: 30px;
  /* border-top: solid var(--offwhite) 1px;
  border-bottom: solid var(--offwhite) 1px; */
  cursor: pointer;
}
.view_item:hover {
  /* background-color: var(--primarydark); */
  background-color: var(--mediumgray);

  /* color: var(--lightgray); */
  /* width: 240px; */
}
.view_active {
  /* padding-left: 25px; */
  /* background-color: var(--primarylight); */
  /* width: calc(100% + 1px); */
  /* height: 100px; */
  /* width: 241px; */
  /* border: solid 1px var(--outline); */
  /* border-right: none;
  border-left: solid 5px var(--forestgreen); */

  /* background-color: var(--white); */
  /* background-color: var(--offwhite); */
  /* background-color: #fafafa; */
  background-color: white;
  
  color: var(--black);
}
/* .view_active:hover {
  
  background-color: var(--white);
  color: var(--black);
} */
.team_list {
  /* background-color: var(--offwhite); */
  /* background-color:#eeeeee; */
  /* background-color: #fafafa; */
  background-color: white;
  color: var(--black);
  /* padding-left: 35px; */
  /* width: 241px; */
  /* background-color: var(--red); */
}

.team_list_item {
  cursor: pointer;
  /* padding: 8px 16px; */
  padding: 8px 45px;
  height: 18px;
  display: flex;
  direction: row;
  align-content: center;
  /* width: 241px; */
  /* padding-left: 50px; */
  /* padding-left: 40px; */
  /* width: fit-content; */
}
.team_list_item:hover {
  background-color: #eee;
}
.team_list_item_active {
  background-color: var(--blue);
  /* background-color: var(--forestgreen); */
  color: var(--white);
  /* background-color: var(--white); */
  /* background-color: #ffffff; */
  /* color: var(--black); */
  /* border-radius: 20px; */
  /* border-bottom: solid 4px var(--forestgreen); */
}
.team_list_item_active:hover {
  background-color: var(--blue);
}
.section_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.section_header_name {
  font-size: 18px;
  font-weight: 700;
}
.sidebar_filter {
  width: 100%;
  max-width: calc(var(--sidebar_width) - 60px);
  /* background-color: var(--primarylight);
  color: var(--lightgray); */

  /* background-color: var(--mediumgray); */
  background-color: var(--white);
  border: none;
  /* color: var(--lightgray); */

  
}
.sidebar_filter::-webkit-input-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter::-moz-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter:-ms-input-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter::-ms-input-placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}
.sidebar_filter::placeholder {
  /* color: var(--lightgray); */
  color: var(--black);
}